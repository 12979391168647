@import "~antd/dist/antd.less";

.site-layout-content {
  padding: 24px;
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.menu-bar {
  float: right;
}

.layout {
  min-height: 100vh;
}

.logo {
  float: left;
}

@primary-color: orange;@link-color: orange;